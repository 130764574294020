import React, { useContext, useEffect } from "react";

import {
  Authenticator,
  useAuthenticator,
  CheckboxField,
  useTheme,
  View,
  Text,
} from "@aws-amplify/ui-react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { signIn, confirmSignUp, SignInInput } from "aws-amplify/auth";
import { AuthContext } from "../../providers/userProvider";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import { AuthData } from "types/auth";
I18n.putVocabularies(translations);
I18n.setLanguage("en");
I18n.putVocabulariesForLanguage("en", {
  "Password must have lower case letters":
    "Password must have at least one lower case letter",
  "Password must have upper case letters":
    "Password must have at least one upper case letter",
  "Password must have numbers": "Password must have at least one number",
  "Password must have special characters":
    "Password must have at least one special character",
  "An account with the given email already exists.":
    "An account with the given email already exists. Please try to sign in, or click the Forgot Your Password link to re-set your password",
  "We Emailed You": "We emailed you",
  "Your code is on the way. To log in, enter the code we emailed to":
    "We want to verify that you have access to the email address you've entered:",
  "It may take a minute to arrive":
    "Please check your inbox for the code we sent you and enter it below. Please note it may take a few minutes to arrive, or may end up in your spam folder",
  "Confirm TOTP Code": "Confirm two-Factor Authentication Code",
});
interface AmplifyAuthenticatorProps {
  open: boolean;
  goToSignUp: boolean;
  handleClose: () => void;
}
const AmplifyAuthenticator: React.FC<AmplifyAuthenticatorProps> = ({
  open,
  goToSignUp,
  handleClose,
}) => {
  const navigate = useNavigate();
  const { signOut, toSignUp, toSignIn, route } = useAuthenticator((context) => [
    context.route,
  ]);
  const { userData, isAccountRejected, setIsAccountRejected } =
    useContext<AuthData>(AuthContext);
  const signin = async () => {
    handleClose();
    if (userData?.user?.userId === undefined) {
      signOut();
      navigate(`/signup/${userData?.sub}`, {
        state: { sub: userData?.sub, email: userData?.email },
      });
    }
  };

  useEffect(() => {
    if (userData?.user) {
      handleClose();
      signin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.user]);
  useEffect(() => {
    if (goToSignUp === true) {
      setTimeout(() => {
        toSignUp();
      }, 10);
    } else {
      toSignIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToSignUp]);
  const onClose = () => {
    if (["forgotPassword", "signIn", "signUp"].includes(route)) {
      handleClose();
    }
    setTimeout(() => {
      setIsAccountRejected(false);
    }, 1000);
  };
  const passwordSettings = {
    minLength: 8,
    requireLowercase: true,
    requireUppercase: true,
    requireNumbers: true,
    requireSpecialCharacters: true,
  };
  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your email",
        isRequired: true,
        label: "Email:",
      },
    },
    signUp: {
      username: {
        placeholder: "Enter your email",
        isRequired: true,
        label: "Email:",
      },
    },
    forgotPassword: {
      username: {
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email:",
      },
    },
  };
  const components = {
    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();
        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            {/* Append & require Terms & Conditions field to sign up  */}
            <CheckboxField
              value="yes"
              errorMessage={validationErrors.acknowledgement as any}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              isRequired={false}
              label={
                <>
                  Accepting {" "}
                  <a href='/agreement-policies'
                    target='_blank'>program guidelines</a>{" "}  and{" "}
                  <a href='/policy'
                    target='_blank'>privacy policy</a>{" "}
                </>
              }
            />
          </>
        );
      },
      Footer() {
        const { tokens } = useTheme();

        return (
          <View
            textAlign="center"
            paddingInlineEnd={tokens.space.small}
            paddingInlineStart={tokens.space.small}
          >
            <Text color={tokens.colors.neutral[80]}>
              We're using an external service to keep your email and password
              secure
            </Text>
          </View>
        );
      },
    },
  };
  const services = {
    async validateCustomSignUp(formData: any) {
      if (
        "acknowledgement" in formData &&
        formData?.acknowledgement === undefined
      ) {
        return {
          acknowledgement: "You must agree to the Terms & Conditions",
        };
      }
      if (
        ["username", "password", "confirm_password"].every(
          (t) => t in formData
        ) &&
        !("acknowledgement" in formData)
      ) {
        return {
          acknowledgement: "You must agree to the Terms & Conditions",
        };
      }
    },
    async handleSignIn(formData: { username: string; password: string }) {
      const { username, password } = formData;
      const result = await signIn({ username, password });
      if (result.nextStep.signInStep === "CONFIRM_SIGN_UP") {
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("password", password);
      }

      return result;
    },
    async handleConfirmSignUp(formData: {
      username: string;
      confirmationCode: string;
    }) {
      const { username, confirmationCode } = formData;
      const result = await confirmSignUp({
        username,
        confirmationCode,
        options: { autoSignIn: true },
      });
      if (
        result.isSignUpComplete &&
        result.nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN"
      ) {
        return result;
      } else {
        const data = {
          username: sessionStorage.getItem("username"),
          password: sessionStorage.getItem("password"),
        } as SignInInput;
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("password");
        await signIn(data);
        window.location.reload();
      }
    },
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        {isAccountRejected ? (
          <>
            <DialogTitle sx={{ my: 2 }}>
              {" "}
              <Typography sx={{ color: "neutral" }} variant="subtitle1">
                Account Rejected
              </Typography>{" "}
            </DialogTitle>
            <DialogContent>
              <Typography variant="bodyCopyRegular" gutterBottom>
                We're sorry, but it appears that the email you used has already
                been rejected. Please contact us for further assistance. Thank
                you for your understanding.
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => onClose()}
                >
                  Got it
                </Button>
              </Box>
            </DialogContent>
          </>
        ) : (
          <Authenticator
            passwordSettings={passwordSettings}
            services={services as any}
            formFields={formFields}
            components={components}
          />
        )}
      </Dialog>
    </div>
  );
};
export default AmplifyAuthenticator;
